import React from 'react';

import {Col, Container, Row, setConfiguration} from 'react-grid-system';
import './App.sass'
import {Heading} from "./components/Heading";
import {StoryPoint} from "./components/StoryPoint";

import zennolab from './static/zennolab.png'
import sber from './static/sber.png'
import edu from './static/edu.png'
import lifepay from './static/lifepay.png'

import db from './static/skills/database.svg'
import srv from './static/skills/server.svg'
import desktop from './static/skills/desktop.svg'
import rocket from './static/skills/rocket.svg'
import {Skill} from "./components/Skill";
import zenfood from './static/zfsm.png'
import greenbin from './static/gb.jpg'
import tattoo from './static/tattoo-machine.svg'
import fortisLogo from './static/fortisLogo.svg'

setConfiguration({maxScreenClass: 'lg', gridColumns: 12, gutterWidth: 0});


function App() {
    return (<Container>
        <Row>
            <Col>
                <div className="Page">
                    <Heading/>
                    <h1 className="title">Story</h1>
                    <div className={"Story reverse"}>
                        <StoryPoint sm title="Started coding at school" icon={edu}/>

                        <StoryPoint title="ZennoLab" icon={zennolab} subtitle="Nov 2017 - Dec 2019">
                            <p>Desktop developer for the <a
                                href="https://zennolab.com/en/products/zennoposter/">ZennoPoster</a> project</p>
                            <p className="sub">.NET Framework 4.5</p>
                            <p>Fullstack developer & admin on the <a
                                href="https://capmonster.cloud/">CapMonster</a> project</p>
                            <p className="sub">
                                .NET Core 2.2, EntityFramework<br/>
                                Redis, PostgreSQL,<br/>
                                HTML, CSS, JQuery<br/>
                            </p>

                        </StoryPoint>
                        <StoryPoint title="Sber Solutions" icon={sber} subtitle="Feb 2020 - Sep 2020">
                            <p>Fullstack Developer for the ISKRa project -
                                a large personnel accounting and task
                                managment system for government administration. Worked mostly on backend.
                            </p>
                            <Row style={{'marginTop': '15px'}}>
                                <Col>
                                    <p>Backend</p>
                                    <p className="sub">
                                        .NET Core 3.0, EntityFramework<br/>
                                        Redis, MSSQL<br/>
                                        MassTransit, RabbitMQ
                                        <br/>
                                    </p>
                                </Col>
                                <Col>
                                    <p>Frontend</p>
                                    <p className="sub">
                                        ReactJs<br/>
                                        Redux<br/>
                                        SCSS
                                    </p>
                                </Col>
                            </Row>

                        </StoryPoint>
                        <StoryPoint title="LifePay" icon={lifepay} subtitle="Sep 2020 - Jun 2023">
                            <p>Backend Developer for the <a href='https://life-pay.ru/'>Life POS </a> project:
                                an online payment processing, acquiring and
                                fiscalization service. Great unit and integration tests writing experience.</p>


                            <br/>
                            <p className="sub">
                                .NET Core 7.0<br/>
                                Entity Framework<br/>
                                PostgreSQL, Redis<br/>
                                RabbitMQ, Kafka<br/>
                                Docker, Kubernetes, Gitlab CI/CD<br/>
                                XUnit<br/>
                            </p>

                        </StoryPoint>
                        <StoryPoint title="Fortis" icon={fortisLogo} subtitle="Jun 2023 - Now">
                            <p>Backend Developer for the <a href='https://fortis.world/'>Fortis</a> project: a POS &
                                CRM solution designed for processing both online and offline payments, as well as
                                for management and process automation.
                            </p>


                            <br/>
                            <p className="sub">
                                .NET Core 8.0<br/>
                                Entity Framework<br/>
                                PostgreSQL, Redis<br/>
                                Kafka<br/>
                                Docker, Kubernetes, Gitlab CI/CD<br/>
                                XUnit, MediatR<br/>
                            </p>

                        </StoryPoint>

                    </div>
                    <h1 className="title">Projects</h1>
                    <div className={"Story"}>
                         <StoryPoint title="Bad Idea Tattoo Studio" icon={'https://badidea.ink/static/media/logo-sm.0210c127928a6d2b717a.webp'} subtitle="">
                            <p>A <a href={'https://badidea.ink'}>website</a> for a tattoo studio in Haifa. I've soloed all the design and implementation.</p>
                            
                            <p className="sub">
                                .NET Core 8<br/>
                                Entity Framework<br/>
                                SQLite<br/>
                                React                                
                            </p>

                        </StoryPoint>
                         <StoryPoint title="GreenBin" icon={greenbin} subtitle="">
                            <p>A <a href={'https://greenbin.app'}>startup</a> that aims to digitalize recyclable
                                waste collection and reward users for participating in recycling.</p>
                            <p>I am the leader of a small dev team (3 persons incl. me), the only fullstack developer
                                and architect of the project.
                                All the backend and frontend is developed by me. </p>
                            <p className="sub">
                                .NET Core 7<br/>
                                Entity Framework<br/>
                                Redis<br/>
                                PostgreSQL<br/>
                                React<br/>
                                React Native
                            </p>

                        </StoryPoint>
                        <StoryPoint title="ZenFood" icon={zenfood} subtitle="">
                            <p>A sushi delivery <a href={'https://zenfood.ru'}>site</a> I made for my dad. Backend
                                and most of the frontend is developed by me.
                            </p>
                            <p><b>UPD: Zenfood project & site closed due to economical reasons in 2022 :c </b></p>
                            <p className="sub">
                                .NET Core 3.1<br/>
                                Entity Framework<br/>
                                MS SQL<br/>
                                JQuery (shame on me)<br/>
                            </p>

                        </StoryPoint>
                       

                    </div>
                    <h1 className="title">Skills</h1>

                    <Row className={"skillsColumn"}>
                        <Skill title={"Backend"} icon={srv}>
                            ASP.NET Core<br/>
                            EntityFramework<br/>
                            Dapper<br/>
                            <br/>
                            <span>
                                        Node.JS<br/>
                                        Python
                                    Golang
                                    </span>
                        </Skill>
                        <Skill title={"Frontend"} icon={desktop}>
                            HTML CSS<br/>
                            SCSS Sass Less<br/>
                            JavaScript<br/>
                            React.JS<br/>
                            Redux

                        </Skill>
                        <Skill title={"Databases"} icon={db}>
                            MySQL<br/>
                            MS SQL<br/>
                            PostgreSQL<br/>

                            Redis<br/>
                            Mongo<br/>
                        </Skill>
                        <Skill title={"Technologies"} icon={rocket}>
                            RabbitMQ<br/>
                            Docker<br/>
                            Linux<br/>
                            Git<br/>
                            Bash<br/>

                        </Skill>
                    </Row>
                </div>
            </Col>
        </Row>
    </Container>)
}

export default App;
