import React from "react";
import './Skill.sass'
import {Col} from 'react-grid-system';

export class Skill extends React.Component {

    render() {
        return <Col xs={"content"}>
            <div className={"Skill"}>
                <div className={"logo"}>
                    <img src={this.props.icon}/>
                    <h5>{this.props.title}</h5>

                </div>
                <div className={"content"}>
                    <p>
                        {this.props.children}
                    </p>
                </div>
            </div>
        </Col>
    }
}