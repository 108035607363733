import {Col, Row} from "react-grid-system";
import React from "react";
import './StoryPoint.sass'

export function StoryPoint(props) {

    let sm = props.sm ? "sm" : ""

    return <div className={"StoryPoint " + sm}>
        <Row>
            <Col md="content">
                <div className="Side">
                    <Row justify={'center'}>
                        <Col xs={'content'}>
                            <div className={"Logo"}>
                                <img src={props.icon}/>
                            </div>
                        </Col>
                    </Row>
                    <div className="LineContainer">
                        <div className="Line"/>
                    </div>
                </div>
            </Col>
            <Col className="Content">
                <h1>{props.title}</h1>
                <h3>{props.subtitle}</h3>
                {props.children}
            </Col>
        </Row>
    </div>
}