import React from "react";
import './Heading.sass'
import {Col, Row} from "react-grid-system";
import inst from '../static/inst.png'
import telegram from '../static/telegram.png'
import github from '../static/github.png'
import linkedin from '../static/linkedin.svg'
import bitbucket from '../static/bitbucket-512.png'
import morda from '../static/cv_photo.png'

const UrlBadge = (props) => {
    return <div
        style={{"position": "relative",}}>
        <h3 style={{'font':"monospace", "color": "whitesmoke", "background": "black"}}>https://valit.dev/</h3>
    </div>
}

export function Heading() {
    /*const age = Math.abs(new Date(
        Date.now() - new Date(1999, 6, 7))
        .getUTCFullYear() - 1970)*/
    return <div className={"Heading"}>
{/*<UrlBadge/>*/}
        <Row nogutters>
            <Col align="center" md={"content"}>
                <img className="pic" style={{'width': '220px', 'height': '220px'}} src={morda}/>
            </Col>
            <Col>
                <div className="headings">
                    <h1>Maxim Valit</h1>
                    <h3>24 y.o.</h3>
                    <h2>fullstack developer</h2>
                    <h3  style={{'fontFamily':'Source Code Pro','marginTop':'10px','fontWeight':'lighter'}}>https://valit.dev</h3>
                    <h3  style={{'fontFamily':'Source Code Pro','marginTop':'10px','fontWeight':'lighter'}}>max.valit99@gmail.com</h3>

                </div>

                <Row justify="end" nogutters className={"contacts"}>
                    <Col xs="content"><a href=""></a></Col>
                    <Col xs="content"><a href="https://instagram.com/m4k4s"><img src={inst} alt=""/></a></Col>
                    <Col xs="content"><a href="https://t.me/m4k4s"><img src={telegram} alt=""/></a></Col>
                    <Col xs="content"><a href="https://github.com/maxvalit"><img src={github} alt=""/></a></Col>
                    <Col xs="content"><a href="https://bitbucket.com/maxvl"><img
                        style={{'height': '26px', 'width': '26px'}} src={bitbucket} alt=""/></a></Col>
                    <Col xs="content"><a href="https://www.linkedin.com/in/maxim-valit-027574204/"><img  style={{'height': '26px', 'width': '26px'}} src={linkedin} alt=""/></a></Col>
                </Row>
            </Col>

        </Row>

    </div>
}